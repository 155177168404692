@media (min-width: 1400px) {
  .default-layout .logo-section .top-layout-arc {
    transform: translateY(-50%) scaleY(0.45);
  }

  .default-layout .top-layout-logo {
    transform: translateX(-6%) translateY(-65%);
  }

  .section-appointments-cover {
    height: 420px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1400px;
  }
}

@media (max-width: 600px) {
  .default-layout .logo-section .top-layout-arc {
    transform: translateY(-20%) scaleY(1);
  }

  .default-layout .top-layout-logo {
    transform: translateX(-6%) translateY(-35%);
  }

  .default-layout .content-section,
  .default-layout .content-section.signup-section {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .default-layout .content-section {
    justify-content: center;
  }

  .default-layout .content-section .platform {
    justify-content: center;
    align-items: center;
    align-self: center;
  }

  .default-layout .content-section.signup-section {
    justify-content: flex-start;
  }

  .default-layout .content-section .app-logo {
    display: block;
  }

  .appointment-tracking-view {
    flex-direction: column;
    padding-top: 64px;
  }

  .appointment-tracking-view .appointment-tracking-map {
    width: 100%;
    height: 75vh;
  }

  .appointment-tracking-view .appointment-tracking-details {
    width: 100%;
    gap: 32px;
    padding: 24px;
    justify-content: stretch;
    align-items: stretch;
    flex-grow: 1;
  }

  .appointment-tracking-view .appointment-tracking-checkout {
    width: 100%;
  }

  .side-bar {
    width: 100vw;
    height: 100vh;
  }

  .side-bar-title {
    font-size: 1.5rem;
  }

  .side-bar-header {
    padding: 24px 24px 6px 24px;
  }

  .side-bar-body {
    padding: 0px 24px 64px 24px;
  }

  .page-header {
    font-size: 1.5rem;
    margin-bottom: 12px;
  }

  .appointment-page {
    padding-top: calc(var(--header-height) + 24px);
    padding-bottom: var(--header-height);
  }

  .page-header-container {
    flex-wrap: wrap;
    margin-bottom: 12px;
  }

  .page-header-container .btn {
    margin: 0;
  }

  .btn {
    font-size: 13px;
  }
}

@media (min-width: 992px) {
  .navbar-brand .nav-logo-compact {
    display: none;
  }

  .footer .navbar-nav {
    -webkit-flex-direction: row;
    flex-direction: row;
    color: var(--theme-color);
  }

  .footer .navbar-nav .nav-link-icon {
    padding: 0 !important;
    border-radius: 0;
  }

  .footer .navbar-nav .nav-item {
    margin-right: 1rem;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 991px) {
  .dropdown-menu.show {
    position: absolute !important;
  }

  .navbar-brand {
    margin-right: 0;
  }

  .navbar-brand .nav-logo-compact {
    display: block;
  }

  .navbar-brand .nav-logo-full {
    display: none;
  }

  .navbar-main .profile-menu.dropdown-menu {
    min-width: 19rem;
    max-width: 19rem;
    left: -12px !important;
    overflow-x: hidden !important;
  }

  .footer .navbar-nav {
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .footer .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
  }

  .footer .navbar-nav .nav-link .nav-link-inner--text {
    font-size: 0.75rem;
  }

  .navbar-nav .nav-link {
    color: #fff !important;
  }

  .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.7) !important;
  }

  .nav-profile-text {
    color: var(--theme-dark-color);
  }

  .booking-section {
    flex-direction: column;
  }

  .booking-section .appointment-details-section {
    width: 100%;
  }

  .booking-section .checkout-section {
    width: 100%;
    margin-top: -124px;
  }

  .navbar-collapse {
    overflow-y: unset;
  }

  .navbar-collapse .profile-menu-dropdown,
  .navbar-collapse .navbar-nav-hover {
    margin-left: 0 !important;
    margin-bottom: 12px !important;
  }

  .side-bar {
    --header-height: 64px;
    padding-top: calc(var(--header-height) - 1px);
  }

  .section-landing-cover > div {
    width: 100%;
    z-index: 10;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .section-landing-cover .landing-page-mask {
    opacity: 0.3;
    height: 80%;
    transform: translateX(60%) translateY(-20%);
    z-index: 5;
  }
}

:root {
  font-family: "Open Sans", sans-serif !important;
  --theme-color: #3dcb90;
  --theme-dark-color: #002919;
  /* --theme-dark-color: #189a62; */
  --accent-color: #fff;
  --accent-light-color: #fff;
  --main-text-color: #232323;
  --sub-text-color: #929292;
  --border-color: rgb(231, 231, 231);
  --success-background: rgb(221, 252, 230);
  --success-color: rgb(32, 124, 65);
  --error-color: #dd3333;
  --error-background-color: rgba(237, 27, 36, 0.12);
  --theme-background-color: rgba(0, 81, 210, 0.12);
  --theme-hover-color: rgba(0, 81, 210, 0.2);
  --header-height: 68px;
}

a {
  color: var(--theme-dark-color);
}

a:hover {
  color: var(--theme-color);
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important;
}

.brand-font {
  font-family: "Kodchasan";
}

.appointment-view-sidebar {
  background-color: #fff;
  height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 80px 40px;
}

.inline {
  display: inline-block;
}

.zig-zag-container {
  position: relative;
  padding: 70px 8px 50px 8px;
  background: #94ffff;
}
.zig-zag-container::after {
  background: linear-gradient(-135deg, #ffffff 16px, transparent 0), linear-gradient(135deg, #ffffff 16px, transparent 0);
  background-position: left-top;
  background-repeat: repeat-x;
  background-size: 32px 32px;
  content: " ";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 32px;
}

.carousel-item img {
  height: 580px;
  object-fit: cover;
}

.carousel-caption {
  position: absolute;
  right: 5%;
  bottom: 70px;
  left: 53%;
  z-index: 10;
  padding-top: 20px;
  padding: 20px;
  color: black;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
}
.hero-card {
  position: absolute;
  right: 5%;
  bottom: 70px;
  left: 53%;
  z-index: 10;
  padding-top: 20px;
  padding: 20px;
  color: black;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
}

.btn-knockout {
  background-color: #fff;
}

.btn {
  border-radius: 27px;
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: normal;
  font-weight: 700;
}

.btn-primary {
  background-color: var(--theme-dark-color);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--theme-dark-color);
}

.btn-danger {
  color: var(--error-color);
  background-color: var(--error-background-color);
  border-color: var(--error-color);
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}

.btn-danger:hover {
  color: #fff;
  background-color: var(--error-color);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: var(--sub-text);
  background-color: var(--border-color);
  border-color: var(--border-color);
  opacity: 0.5;
}

.btn-link {
  text-transform: none;
  border: none;
  color: var(--theme-dark-color);
  font-weight: 500;
  box-shadow: none !important;
  background: none !important;
  padding: 0;
  margin-right: 0;
}

.btn-link:hover {
  box-shadow: none !important;
  background: none !important;
  color: var(--theme-color);
}

.black-btn {
  background-color: black;
  color: white;
  border-color: black;
}

.black-btn:hover {
  background-color: black;
  color: white;
  border-color: black;
}

.row-grid.appt-cards {
  overflow-x: visible;
  display: flex;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.55;
}

.btn-block + .btn-block {
  margin-top: 0;
}
.btn-outline-danger {
  color: #ea1b27;
  background-color: #fff;
  border-color: #ea1b27;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #c71720;
  border-color: #c71720;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #ea1b25c7;
  border-color: #ea1b25c7;
}
.btn-outline-default {
  color: black;
  background-color: #fff;
  border-color: black;
}
.btn-outline-default:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-outline-default:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #d6dae2;
  border-color: #cfd3dc;
}
[class*="btn-outline-"] {
  border-width: 1px;
}

.btn-outline-secondary {
  border-color: var(--theme-dark-color);
  border-width: 2px;
  color: var(--theme-dark-color);
}

.btn-outline-secondary:hover {
  border-color: var(--theme-dark-color);
  color: var(--theme-dark-color);
  background-color: var(--theme-background-color);
}

.btn-outline-secondary:not(:disabled):not(.disabled):active {
  border-color: var(--theme-dark-color);
  color: #fff;
  background-color: var(--theme-dark-color);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: var(--theme-dark-color);
  border-color: var(--theme-dark-color);
}

button.btn.disabled:hover:not(.btn-primary):not(.btn-secondary),
button.btn:disabled:hover:not(.btn-primary):not(.btn-secondary) {
  box-shadow: none !important;
  background-color: transparent;
}

.card {
  border: 1px solid #c5cad3;
  border-radius: 0.3rem;
}

.footer {
  background-color: var(--theme-dark-color) !important;
  padding: 1.5rem 0;
}

.footer p {
  font-size: 14px;
}

.footer .navbar-nav .nav-link-icon {
  padding: 0 !important;
  border-radius: 0;
  display: flex;
  align-items: center;
}

.content-center {
  justify-content: center;
  text-align: center;
  margin: auto;
  display: block;
}
.content-end {
  justify-content: flex-end;
  text-align: end;
}
.link-text {
  color: #6bd8e7;
  cursor: pointer;
}
.page-header {
  margin-bottom: 40px;
}
.page-back-arrow {
  width: 32px;
  height: 32px;
  color: var(--theme-dark-color);
}
/* CARDS */
.avvy-card {
  padding: 16px;
  border: 2px solid var(--theme-dark-color);
  border-radius: 4px;
  overflow: hidden;
}
.service-price-sidebar {
  font-weight: 400;
  color: var(--theme-color);
  font-size: 18px;
}
.upload-file-box {
  background-color: #94ffff;
  height: 280px;
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avvy-card.nav {
  padding: 10px 6px;
  width: 100%;
}
.avvy-card.nav .row {
  align-items: center;
  width: 100%;
  margin: 0;
}
.avvy-card.danger {
  border-color: #eb6856;
  color: #eb6856;
}
.avvy-card-blue {
  padding: 16px;
  background-color: var(--theme-dark-color);
  color: #fff;
  border-radius: 4px;
}
.avvy-card-small {
  border: 2px solid var(--theme-dark-color);
  border-radius: 4px;
  padding: 4px 12px;
  min-width: 100px;
}
.avvy-card-header {
  color: var(--main-text-color);
  font-size: 17px;
  font-weight: 700;
}

.avvy-card-footer {
  border-top: dashed 1px var(--theme-color);
  padding-top: 12px;
}

.service-buttons {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
}
.bold-blue {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 700;
  color: var(--theme-dark-color);
}
.bold-blue-light {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 700;
  color: var(--theme-color);
}
.light-grey {
  font-size: 16px;
  font-weight: 300;
  color: #bebebe;
}

/* SIDEBAR */
.opacity-background {
  z-index: 10;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.side-bar {
  padding-top: calc(var(--header-height) - 1px);
  will-change: transform;
  position: fixed;
  right: 0;
  top: 0;
  width: 560px;
  height: 100vh;
  background-color: #fff;
  z-index: 2;
  transition: all 0.4s ease-in-out;
  will-change: transform;
}

.side-bar.close {
  transform: translateX(100%);
}
.side-bar.open {
  transform: translateX(0);
}

.side-bar-content {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100%;
  flex-grow: 1;
}

.side-bar-content::-webkit-scrollbar {
  display: none;
}

.side-bar-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding-top: var(--header-height);
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.side-bar-title {
  font-size: 1.75rem;
}

.side-bar-body {
  padding: 0px 32px 64px 32px;
  height: auto;
}

.side-bar-title.knockout {
  color: #fff;
  margin-top: 20px;
  padding-right: 160px;
}

.service-sidebar-mask {
  position: absolute;
  top: -0;
  right: -88px;
  height: 220px;
}

.side-bar-toggle {
  cursor: pointer;
}

.side-bar-header {
  padding: 32px 32px 20px 32px;
  flex-shrink: 0;
}

.side-bar-header.filled {
  position: relative;
  height: auto;
  background-color: var(--theme-color);
  overflow: hidden;
  padding-bottom: 40px;
}

.side-bar-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 10px;
  background-color: #fff;
}

.side-bar.open .side-bar-footer {
  box-shadow: rgba(0, 0, 0, 0.06) 0px -4px 10px;
}

.schedule-date {
  display: flex;
  flex-direction: column;
  padding: 6px 8px;
  width: 64px;
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;
  gap: 2px;
  transition: background 0.3s ease-in-out;
  border-radius: 4px;
}

.schedule-date:hover,
.schedule-time:hover {
  background: var(--border-color);
}

.schedule-date > span,
.schedule-time > span {
  white-space: nowrap;
  font-size: 13px;
  line-height: 13px;
}

.schedule-date > span.sub-text {
  font-size: 11px;
}

.schedule-date.selected {
  background-color: var(--theme-dark-color);
}

.schedule-date.selected > span {
  color: #ffffff !important;
}

.schedule-time {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 12px;
  cursor: pointer;
  gap: 2px;
  font-weight: 600;
  text-align: center;
  color: var(--theme-dark-color);
  transition: background 0.3s ease-in-out;
  border-radius: 4px;
}

.schedule-time.selected {
  font-weight: bold;
  color: var(--theme-dark-color);
  background-color: var(--theme-background-color);
}

.open-chat-button {
  position: absolute;
  right: 35px;
  bottom: 40px;
  z-index: 1;
  border-radius: 50%;
  background-color: var(--theme-dark-color);
  padding: 20px;
}

.flip-y {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--theme-dark-color);
  border-radius: 4px;
}

.navbar {
  padding: 0.75rem 1rem;
}

.test-description ul li::marker {
  color: var(--theme-dark-color);
}

.service-checkbox.custom-checkbox .custom-control-label::before {
  border: var(--theme-dark-color) 1px solid;
}

.navbar-toggler {
  margin-left: auto;
}

.navbar-collapse.collapse .navbar-toggler {
  width: auto;
  height: auto;
}

.navbar-collapse.collapse .navbar-toggler :nth-child(1) {
  transform: none;
}

.navbar-collapse.collapse > li,
.navbar-collapse.collapse > ul {
  margin-bottom: 12px;
}

.navbar-collapse.collapse.show .navbar-collapse-header {
  display: flex;
  align-items: center;
}

.navbar-collapse.collapse.show .nav-link-inner--text,
.navbar-collapse.collapse.show .accent-text {
  color: var(--theme-dark-color);
}

.navbar-avvy {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: var(--theme-dark-color) !important;
  border: 0;
  box-shadow: 0px 3px 12px #0000000d;
  color: #fff;
}
.navbar-avvy .navbar-nav .nav-link {
  color: #fff !important;
  font-size: 14px;
}
.navbar-avvy .navbar-nav .nav-item {
  color: #fff;
  font-size: 14px;
}

.footer .nav-link {
  color: #fff;
}

.navbar-main .dropdown-menu {
  min-width: 22rem;
}

.navbar-main .dropdown-menu .dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 12px;
}

.navbar-main .dropdown-menu .dropdown-item:not(:last-of-type) {
  border-bottom: 1px solid var(--border-color);
}

.navbar-main .dropdown-menu .dropdown-item.profile-details-item {
  padding: 16px 24px !important;
}

.profile-menu.dropdown-menu .dropdown-item:active {
  background: var(--theme-background-color);
  color: var(--theme-dark-color);
}

.text-danger {
  color: #ea1b27 !important;
}
.test-quest {
  background-color: rgba(197, 202, 211, 0.1);
  padding: 0.5rem 1.5rem;
  margin-bottom: 1rem;
}
.form-check {
  position: relative;
  display: block;
}
.navbar-main h6 {
  font-weight: 400 !important;
}
.navbar-main .dropdown-header {
  /* padding-left: 1rem;
  padding-right: 1rem; */
  color: #000000 !important;
  font-size: 14px;
  text-transform: none;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* LANDING PAGE */
.section-landing-cover {
  position: relative;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  display: flex;
}
.section-landing-content {
  /* width: 50%; */
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}
.section-landing-content img {
  border-radius: 4px;
  max-width: 590px;
  object-fit: contain;
}
.section-landing-content h1 {
  color: white;
  text-transform: uppercase;
  font-size: 55px;
  margin-bottom: 50px;
  text-align: center;
}
.section-landing-content button {
  max-width: 500px;
  width: 100%;
}

.landing-page-mask {
  position: absolute;
  right: 0;
  height: 80%;
  transform: translateX(35%) translateY(-20%);
  top: 0;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  opacity: 0.9;
}

.outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  min-height: 95vh;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  text-align: center;
}

.modal-backdrop {
  background-color: rgb(0, 0, 0);
}

.modal-backdrop.show {
  opacity: 0.3;
}

.input-group-alternative {
  box-shadow: 0 1px 1px rgb(50 50 93 / 10%), 0 1px 0 rgb(0 0 0 / 2%);
  border: 2px solid var(--theme-dark-color);
  transition: box-shadow 0.15s ease;
}

.input-group-alternative input:focus {
  background-color: #ffffff;
  border: none;
  box-shadow: none;
  outline: 0 !important;
  color: #333333;
}

.close {
  color: #2b2b2b;
  opacity: 1;
  text-shadow: none;
}

.modal-header .close {
  position: absolute;
  margin: -1rem -1rem -1rem 0rem;
  padding: 0.9rem 0.9rem 0 0;
}

.modal-header {
  flex-direction: row-reverse;
}
.modal-title {
  /* margin-left: auto; */
  margin-right: auto;
}
.modal a {
  color: black;
  font-weight: 600;
  text-decoration: none;
}

.form-group {
  margin-bottom: 0;
}

.form-group.phone-input input {
  width: 100%;
  background-color: #ffffff;
  border: 2px solid var(--theme-dark-color);
  border-radius: 4px;
  color: #565656;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  box-shadow: none;
}

.form-group.phone-input input:focus {
  background-color: #ffffff;
  border: 2px solid var(--theme-dark-color);
  box-shadow: none;
  outline: 0 !important;
  color: #333333;
}

.phone-input input::placeholder {
  color: #adb5bd;
  opacity: 1;
  font-size: 14px;
}

.form-control {
  border: 2px solid var(--theme-dark-color);
  color: var(--main-text-color);
}

.form-control:focus {
  border: 2px solid var(--theme-dark-color);
  color: var(--main-text-color);
}

.form-control:disabled,
.form-control[readonly] {
  background: rgba(128, 128, 128, 0.08);
}

.custom-control-alternative .custom-control-label::before {
  border: 1px solid var(--theme-dark-color);
  /* box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%); */
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--theme-dark-color);
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--theme-dark-color);
  background-color: var(--theme-dark-color);
  box-shadow: none;
}
.edit-account .form-control {
  background-color: #c5cad321;
  color: #000000;
  padding: 0.625rem 1.5rem;
  border-radius: 0;
  border: 0px;
}
.edit-account .input-group-alternative {
  box-shadow: 0 0 0 rgb(50 50 93 / 10%), 0 0 0 rgb(0 0 0 / 2%);
  border: 0px solid #989ca3;
  transition: box-shadow 0.15s ease;
}
.edit-account .input-group-alternative:focus {
  /* box-shadow: 0 0 0 rgb(50 50 93 / 10%), 0 0 0 rgb(0 0 0 / 2%); */
  border: 0;
  border-bottom: 0px solid #989ca3;
  /* transition: box-shadow .15s ease; */
}
.edit-account .form-group.phone-input input {
  width: 100%;
  background-color: #c5cad321;
  border: 0px solid #989ca3;
  border-radius: 0px;
  color: #000000;
  padding: 0.625rem 1.5rem;
  box-shadow: none;
  font-size: 14px;
}
.edit-account .form-group.phone-input input:focus {
  background-color: #fff;
  border: 0px;
  border-bottom: 1px solid #989ca377;
  border-top: 1px solid #989ca377;
}
.edit-account .input-group-alternative input:focus {
  background-color: #fff;
  border: 0px;
  border-bottom: 1px solid #989ca377;
  border-top: 1px solid #989ca377;
}
.edit-account .form-group.phone-input input::placeholder {
  color: #000000 !important;
}
.edit-account-line {
  cursor: pointer;
  width: 100%;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
  border-bottom: 2px solid #f1f2f4;
  border-top: 2px solid #f1f2f4;
  background-color: #ffffff;
  border-radius: 0;
}
.edit-account-line span {
  font-weight: bold;
}
.edit-account label {
  font-weight: bold;
  color: #000000;
}
.edit-account {
  font-size: 14px;
}
.edit-account .btn-secondary {
  box-shadow: none;
}

.btn-outline-primary {
  border-width: 2px;
  color: var(--theme-dark-color);
  border-color: var(--theme-dark-color);
}

.btn-outline-primary:hover {
  border-color: var(--theme-dark-color);
  color: var(--theme-dark-color);
  background-color: var(--theme-background-color);
}

.btn-outline-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  border-color: var(--theme-dark-color);
  background-color: var(--theme-dark-color);
}

button:focus {
  outline: 0;
  outline: 0 auto -webkit-focus-ring-color;
}

.edit-account .btn:hover {
  transform: translateY(0);
}

.password-eye-icon {
  position: absolute;
  right: 10px;
  top: 38px;
  width: 24px;
  height: 24px;
  z-index: 100;
  color: var(--accent-color);
  cursor: pointer;
}

.rate-modal .form-control {
  border: 1px solid #989ca3;
  height: 180px;
}
.appt-details-modal .client-info-card {
  color: #989ca3;
  padding: 12px 0;
  border-bottom: 1px solid #989ca3;
}
.appt-details-modal .client-info-card p {
  margin-bottom: 0;
}
.cllient-name,
.service-price {
  font-weight: bold;
  color: #000000;
}
.service-price {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  color: #000000;
}
.service-info {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}
.gray-btn {
  background-color: #c5cad3;
  border-color: #c5cad3;
}
.appt-history-card {
  color: #989ca3;
  border: 1px solid #c5cad3;
  padding: 10px;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 15px;
  box-shadow: 0 0px 7px rgb(50 50 93 / 10%), 0 0px 7px rgb(0 0 0 / 6%);
}
.rate-btn {
  cursor: pointer;
}
.credit-cards-table span {
  color: #ea1b27;
}
.password-eye-icon {
  position: absolute;
  right: 10px;
  top: 40px;
  width: 25px;
}
.displayblock {
  display: block;
}
.credit-cards-table .btn-secondary {
  color: #fff;
  background-color: #c33e2a00 !important;
  border-color: #c33e2a00 !important;
  box-shadow: none;
}
.credit-cards-table .dropdown-item.active {
  color: #fff;
  text-decoration: none;
  background-color: var(--theme-color) !important;
  border-radius: 4px;
}
.mapboxgl-ctrl-top-left {
  width: 94% !important;
}

.booking-radios .custom-control-input ~ .custom-control-label::before {
  border-color: #000;
  background-color: #fff;
}

.booking-radios .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #ea1b27;
  background-color: #fff;
}
.booking-radios .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ea1b27;
  background-image: none;
  border-radius: 20px;
  width: 14px;
  height: 14px;
  margin: 3px;
}

.section-appointments-cover {
  position: relative;
  padding: 40px 0 15px;
  color: #fff;
  height: 360px;
  overflow: hidden;
  width: 100%;
  min-width: 100vw;
  display: flex;
  align-items: center;
}

.section-appointments-cover .home-banner-text p {
  color: var(--theme-dark-color);
  font-size: 18px;
  line-height: 1.5rem;
}

.section-appointments-cover .home-banner-text {
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10%;
}

.section-appointments-cover .home-star-piece {
  z-index: 1;
  position: absolute;
  color: #fff;
  opacity: 0.4;
  top: 0;
  left: 0;
  height: 160%;
  transform-origin: 50% 50%;
  transform: translateY(-10%) translateX(0%) rotate(88deg);
}

.section-appointments-cover .home-banner {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  z-index: 0;
}

.service-card {
  cursor: pointer;
  width: 100%;
  height: 112px;
  max-height: 112px;
  --shadow-color: 180deg 1% 67%;
  border: none;
  transition: box-shadow ease-in-out 250ms;
  box-shadow: 0px 0.4px 0.5px hsl(var(--shadow-color) / 0.17), 0px 1.3px 1.7px -0.5px hsl(var(--shadow-color) / 0.22),
    0px 2.9px 3.7px -1px hsl(var(--shadow-color) / 0.28), 0.1px 6.5px 8.3px -1.5px hsl(var(--shadow-color) / 0.33);
}

.service-card:hover {
  box-shadow: 0px 0.7px 0.9px hsl(var(--shadow-color) / 0.17), 0px 2.3px 2.9px -0.5px hsl(var(--shadow-color) / 0.22),
    0px 5.2px 6.6px -1px hsl(var(--shadow-color) / 0.28), 0px 11.6px 14.8px -1.5px hsl(var(--shadow-color) / 0.33);
}

.service-card .card-body {
  padding: 32px 24px;
  display: flex;
  align-items: center;
}

.service-image {
  width: 164px;
  height: 164px;
  overflow: hidden;
  /* border-radius: 10%; */
  border-radius: 6px;
  object-fit: cover;
  object-position: center;
  /* border: 1px solid var(--border-color); */
  /* background-color: var(--border-color); */
  color: var(--sub-text-color);
}

.service-card .service-image {
  width: 82px;
  height: 82px;
  overflow: hidden;
  border-radius: 16px;
  position: absolute;
  object-fit: cover;
  object-position: center;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  /* background-color: var(--border-color); */
  color: var(--sub-text-color);
}

.appointment-card {
  position: relative;
  background-color: #fff;
  color: var(--main-text-color);
  --shadow-color: 180deg 1% 67%;
  border: none;
  transition: box-shadow ease-in-out 250ms;
  box-shadow: 0px 0.4px 0.5px hsl(var(--shadow-color) / 0.17), 0px 1.3px 1.7px -0.5px hsl(var(--shadow-color) / 0.22),
    0px 2.9px 3.7px -1px hsl(var(--shadow-color) / 0.28), 0.1px 6.5px 8.3px -1.5px hsl(var(--shadow-color) / 0.33);
}

.appointment-card:hover {
  box-shadow: 0px 0.7px 0.9px hsl(var(--shadow-color) / 0.17), 0px 2.3px 2.9px -0.5px hsl(var(--shadow-color) / 0.22),
    0px 5.2px 6.6px -1px hsl(var(--shadow-color) / 0.28), 0px 11.6px 14.8px -1.5px hsl(var(--shadow-color) / 0.33);
}

.appointment-card .service-image {
  width: 82px;
  height: 82px;
  overflow: hidden;
  border-radius: 16px;
  position: absolute;
  object-fit: cover;
  object-position: center;
  right: 20px;
  top: 20px;
  background-color: var(--border-color);
  color: var(--sub-text-color);
}

.appointment-card .card-body {
  padding: 20px 20px;
}

.appointment-card .btn {
  box-shadow: none;
  transform: none;
  padding: 6px 12px;
  border-radius: 4px;
}

.appointment-card .btn:hover {
  box-shadow: none;
  transform: none;
}

.appointment-card .btn-secondary {
  background-color: rgba(107, 217, 231, 0.2) !important;
  border-color: rgba(107, 217, 231, 0.2) !important;
  color: var(--theme-dark-color);
}

.appointment-card .btn-secondary {
  box-shadow: none;
  transform: none;
}

.appointment-card .btn-secondary:hover {
  background-color: rgba(107, 217, 231, 0.4) !important;
  box-shadow: none;
  transform: none;
}

.landing-page .btn {
  padding: 0.625rem 1.25rem;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.landing-page {
  background-color: var(--theme-dark-color);
}

.landing-page .btn-secondary {
  box-shadow: 0 15px 35px rgb(50 50 93 / 10%), 0 5px 15px rgb(0 0 0 / 7%) !important;
  background-color: #fff;
  color: var(--theme-dark-color);
  width: 100%;
  border-radius: 27px;
}

.landing-page .btn-outline-secondary {
  width: 100%;
  border-radius: 27px;
  border-color: #fff;
  color: #fff;
}

.landing-page h1 {
  color: #fff;
  font-size: 50px;
  line-height: 1.2;
  margin-bottom: 70px;
}

.stylized-header {
  color: var(--theme-dark-color);
  font-size: 50px;
  text-align: left;
  line-height: 1.2;
  font-weight: 600 !important;
  font-family: "Kodchasan";
}

.landing-page h1::after,
.stylized-header::after {
  content: url("../img/svg/brush-stroke.svg");
  position: absolute;
  top: 75%;
  right: 0px;
}

.inner {
  position: relative;
}

.form-check,
.form-check-label {
  cursor: pointer;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--theme-dark-color);
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--theme-dark-color);
  background-color: var(--theme-dark-color);
  box-shadow: none;
}
input[type="month"]::-webkit-inner-spin-button,
input[type="month"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("../img/svg/radio_checked.svg");
  background-size: 20px;
  background-color: #fff;
  border-radius: 0;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--theme-dark-color);
}

.avvy-card .avvy-card-icon {
  height: 24px;
  width: 24px;
  object-fit: cover;
  color: var(--theme-dark-color);
}

label {
  margin-bottom: 4px;
}

.avvy-label {
  color: var(--theme-dark-color);
  font-weight: 700;
}

.app-details-row {
  display: grid;
  grid-template-columns: minmax(80px, 25%) 1fr;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
}

.app-details-label {
  width: 30%;
  color: black;
  font-weight: 500;
  white-space: nowrap;
}

.app-details-value {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--sub-text-color);
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.gap-2 {
  gap: 2px;
}

.gap-6 {
  gap: 6px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

.gap-32 {
  gap: 32px;
}

.w-16 {
  width: 16px;
}

.h-16 {
  height: 16px;
}

.w-24 {
  width: 24px;
}

.h-24 {
  height: 24px;
}

.w-32 {
  width: 32px;
}

.h-48 {
  height: 48px;
}

.w-48 {
  width: 48px;
}

.w-full {
  width: 100%;
}

.w-half {
  width: 50%;
}

.h-32 {
  height: 32px;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.main-text {
  color: var(--main-text-color);
}

.sub-text {
  color: var(--sub-text-color);
}

.white-text {
  color: white;
}

.theme-text {
  color: var(--theme-color);
}

.theme-dark-text {
  color: var(--theme-dark-color);
}

.accent-text {
  color: #3dcb90;
}

.error-text {
  color: var(--error-color);
}

.success-text {
  color: var(--success-color);
}

.text-normal {
  font-size: 0.875rem;
}

.text-small {
  font-size: 0.8rem;
  line-height: 16px;
}

.regular {
  font-weight: 400;
}

.bold {
  font-weight: 700;
}

.semibold {
  font-weight: 500;
}

.separator {
  display: flex;
  background: var(--border-color);
}

.separator.horizontal {
  height: 1px;
}

.separator.vertical {
  width: 1px;
  height: 100%;
}

.d-flex .separator.vertical {
  height: auto;
  align-self: stretch;
}

.mapboxgl-ctrl-geocoder {
  width: 100% !important;
  max-width: 100% !important;
}

.input-group .rdt .form-control:focus {
  border: none !important;
}

.input-group .right-picker .rdtPicker {
  right: 0%;
}

.custom-switch.custom-control {
  padding-left: 1.7rem !important;
  margin-left: -1.7rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.schedule-scroll-container {
  width: max-content;
  display: flex;
  flex-shrink: 0;
  gap: 6px;
  will-change: transform;
  transition: transform 0.5s ease-in-out;
}

.invisible-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.invisible-scroll::-webkit-scrollbar {
  display: none;
}

.error-alert {
  background-color: var(--error-background-color);
  border: 1px solid var(--error-color);
  padding: 1.4rem;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  color: var(--error-color);
  border-radius: 4px;
  overflow: hidden;
  white-space: pre-line;
  word-break: normal;
}

.warning-alert {
  background-color: rgb(255 237 213);
  border: 1px solid rgb(234 88 12);
  padding: 1.4rem;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  color: rgb(234 88 12);
  border-radius: 4px;
  overflow: hidden;
  white-space: pre-line;
  word-break: normal;
}

.form-group.phone-input input {
  padding-left: 1.75rem;
  font-size: 0.875rem;
}

.side-bar-content .form-group.phone-input input {
  padding-left: 0.75rem;
}

.side-bar-content label {
  color: currentColor;
  font-weight: 400;
  font-size: 16px;
}
.dob-readonly-input.form-control {
  background: transparent;
  cursor: pointer;
}

.appointment-tracking-view {
  padding-top: var(--header-height);
}

.appointment-tracking-view .appointment-tracking-map {
  width: 70%;
}

.appointment-tracking-view .appointment-tracking-details {
  width: 30%;
  min-width: 340px;
  gap: 32px;
  padding: 24px;
}

.nav-profile-text {
  color: #fff;
}
.button-selected {
  background-color: var(--theme-dark-color) !important;
  color: #fff;
}

.button-selected:hover {
  background-color: var(--theme-color) !important;
  color: #fff;
}

.text-dark-blue {
  color: var(--theme-dark-color);
}

.side-bar-footer .btn.btn-secondary {
  border: 1px solid var(--border-color);
  box-shadow: none;
}

.mapboxgl-ctrl-geocoder {
  box-shadow: none !important;
  border: 2px solid var(--theme-dark-color);
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--input {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  line-height: 1.5;
  height: 100%;
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--icon-search {
  top: 10px;
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--button {
  top: 9px;
}

.chat-container {
  position: absolute;
  bottom: calc(100% + 16px);
  right: 0;
  width: 100%;
  flex-direction: column;
  max-height: 400px;
  background: #fff;
  border-radius: 6px;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgb(224, 224, 224) 0px 0px 0px 1px, rgba(17, 17, 26, 0.1) 0px 4px 16px 0px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px 0px, rgba(17, 17, 26, 0.1) 0px 16px 56px 0px;
}

.chat-container .chat-header {
  padding: 10px 16px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.chat-header .chat-collapse-button {
  margin-left: auto;
  width: 24px;
  color: var(--sub-text-color);
  cursor: pointer;
}

.chat-messages-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.chat-container .infinite-scroll-component {
  gap: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
  overflow-x: hidden !important;
}

.chat-container .chat-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  padding: 0 16px;
}

.chat-message-text {
  position: relative;
  display: flex;
  padding: 10px 12px;
  flex-direction: column;
  border-radius: 6px;
  color: var(--main-text-color);
  width: 82%;
  text-align: left;
  align-items: flex-start;
  background-color: var(--border-color);
  font-size: 0.8rem;
  font-weight: 400;
}

.chat-message-time {
  font-size: 0.7rem;
  font-weight: 400;
  color: var(--sub-text-color);
}

.chat-message.self {
  align-items: flex-end;
}

.chat-message.self .chat-message-text {
  background: var(--theme-color);
  color: white;
  text-align: right;
}

.chat-input {
  display: flex;
  position: relative;
  flex-shrink: 0;
  align-items: stretch;
}

.chat-input input {
  border: none;
  border-radius: 6px;
  padding-right: 40px;
}

.chat-input input:focus {
  border: none;
}

.chat-input svg {
  position: absolute;
  width: 24px;
  height: 24px;
  transform: rotate(90deg);
  transform-origin: 50% 50%;
  right: 6px;
  top: 10px;
  color: var(--theme-color);
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.chat-input svg:hover {
  color: var(--theme-dark-color);
  cursor: pointer;
}

.chat-input svg.disabled {
  color: var(--sub-text-color);
  cursor: initial;
}

.chat-loader {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chat-typing-status {
  color: var(--sub-text-color);
  font-size: 0.75rem;
  margin-left: 16px;
  padding: 3px 12px;
  margin-bottom: 16px;
  background: var(--border-color);
  border-radius: 6px;
  margin-right: auto;
}

.chat-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.chat-button > svg {
  margin: 0 !important;
}

.chat-unread-badge {
  position: absolute;
  top: 2px;
  right: 1px;
  background: var(--error-color);
  color: #fff;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.btn-secondary.btn-accent {
  background: var(--accent-light-color);
  border-color: var(--accent-light-color);
  color: var(--theme-dark-color);
  font-weight: 700;
}

.btn-secondary.btn-accent:hover {
  background: var(--accent-color);
  color: var(--theme-color);
}

.btn-secondary.btn-accent:not(:disabled):active {
  color: var(--theme-dark-color);
}

.btn-secondary.btn-accent:disabled {
  color: var(--theme-dark-color);
  background: var(--accent-color);
}

.schedule-calendar .rdt.rdtOpen .rdtPicker {
  padding: 0;
}

.schedule-calendar .rdtPicker thead > tr:first-of-type {
  box-shadow: 0 2px 0 var(--theme-dark-color);
}

.schedule-calendar .rdtPicker .rdtSwitch {
  color: var(--theme-dark-color);
  font-weight: 700;
  height: 32px;
  font-size: 16px;
  line-height: 36px;
}

.schedule-calendar .rdtPicker .rdtSwitch:hover {
  background-color: transparent !important;
  cursor: default !important;
}

.schedule-calendar .rdtPicker th.rdtNext,
.schedule-calendar .rdtPicker th.rdtPrev {
  width: 32px;
  height: 32px;
  font-size: 32px;
  font-weight: 400;
  color: "#3dcb90";
  line-height: 36px;
  text-align: center;
  padding-bottom: 4px;
}

.schedule-calendar .rdtPicker tr .dow {
  color: #000;
  font-weight: 700;
}

.schedule-calendar .rdtDays tr .rdtDay {
  border-radius: 5px;
}

.schedule-calendar .rdtDays tr .rdtDay {
  color: var(--theme-dark-color);
  font-weight: 600;
}

.schedule-calendar .rdtPicker td.rdtDisabled,
.schedule-calendar .rdtPicker td.rdtDisabled:hover {
  color: #bbb;
}

.schedule-calendar .rdtDay.rdtToday.rdtActive,
.schedule-calendar .rdtDay.rdtActive,
.schedule-calendar .rdtDay.rdtActive:hover,
.schedule-calendar .rdtDay.start-date,
.schedule-calendar .rdtDay.end-date,
.schedule-calendar .rdtDay.middle-date,
.schedule-calendar .rdtDay.start-date:hover,
.schedule-calendar .rdtDay.end-date:hover,
.schedule-calendar .rdtDay.middle-date:hover {
  background-color: var(--theme-background-color) !important;
  color: var(--theme-dark-color) !important;
  font-weight: 700 !important;
}

.schedule-calendar .rdtDay.rdtOld,
.schedule-calendar .rdtDay.rdtNew {
  opacity: 0 !important;
  cursor: default !important;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type="checkbox"],
  input[type="radio"] {
    --active: var(--theme-dark-color);
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, 0.3);
    --border: var(--theme-dark-color);
    --border-hover: var(--theme-dark-color);
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  }
  input[type="checkbox"]:after,
  input[type="radio"]:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
  }
  input[type="checkbox"]:checked,
  input[type="radio"]:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }
  input[type="checkbox"]:disabled,
  input[type="radio"]:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }
  input[type="checkbox"]:disabled:checked,
  input[type="radio"]:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }
  input[type="checkbox"]:disabled + label,
  input[type="radio"]:disabled + label {
    cursor: not-allowed;
  }
  input[type="checkbox"]:hover:not(:checked):not(:disabled),
  input[type="radio"]:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }
  input[type="checkbox"]:focus,
  input[type="radio"]:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  input[type="checkbox"]:not(.switch),
  input[type="radio"]:not(.switch) {
    width: 21px;
  }
  input[type="checkbox"]:not(.switch):after,
  input[type="radio"]:not(.switch):after {
    opacity: var(--o, 0);
  }
  input[type="checkbox"]:not(.switch):checked,
  input[type="radio"]:not(.switch):checked {
    --o: 1;
  }
  input[type="checkbox"] + label,
  input[type="radio"] + label {
    font-size: 14px;
    line-height: 21px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 4px;
  }

  input[type="checkbox"]:not(.switch) {
    border-radius: 5px;
    border-width: 2px;
  }

  .large input[type="checkbox"]:not(.switch) {
    width: 32px;
    height: 32px;
  }

  input[type="checkbox"]:not(.switch):after {
    width: 5px;
    height: 9px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 6px;
    top: 3px;
    transform: rotate(var(--r, 20deg));
  }

  .large input[type="checkbox"]:not(.switch):after {
    width: 9px;
    height: 14px;
    left: 10px;
    top: 4px;
  }

  input[type="checkbox"]:not(.switch):checked {
    --r: 43deg;
  }
  input[type="checkbox"].switch {
    width: 38px;
    border-radius: 11px;
  }
  input[type="checkbox"].switch:after {
    left: 2px;
    top: 2px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background: var(--ab, var(--border));
    transform: translateX(var(--x, 0));
  }
  input[type="checkbox"].switch:checked {
    --ab: var(--active-inner);
    --x: 17px;
  }
  input[type="checkbox"].switch:disabled:not(:checked):after {
    opacity: 0.6;
  }

  input[type="radio"] {
    border-radius: 50%;
  }
  input[type="radio"]:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, 0.7));
  }
  input[type="radio"]:checked {
    --s: 0.5;
  }
}

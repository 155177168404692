.inline-geocoder .mapboxgl-ctrl-geocoder--input:not(:placeholder-shown)::placeholder {
  color: var(--sub-text);
}

.inline-geocoder .mapboxgl-ctrl-geocoder--input:placeholder-shown::placeholder {
  color: var(--sub-text);
}

.inline-geocoder .mapboxgl-ctrl-geocoder--pin-right {
  display: none;
}

.splash-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.default-layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
  width: 100%;
}

.default-layout .content-section {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
}

.default-layout .content-section.signup-section {
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 75px 0px;
}

.default-layout .logo-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  color: var(--theme-dark-color);
  align-items: center;
  justify-content: center;
}

.default-layout .top-layout-arc {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(-50%) scaleY(0.7);
}

.default-layout .top-layout-logo {
  width: 300px;
  max-width: 50%;
  margin: 5% auto;
  transform: translateX(-6%) translateY(-15%);
  z-index: 1;
}

.default-layout .logo-section .logo-background {
  z-index: 0;
  height: 100%;
  width: 100%;
}

.default-layout .logo-section .app-logo {
  width: 70%;
  max-width: 360px;
  z-index: 1;
  position: absolute;
  object-fit: contain;
}

.default-layout .content-section .btn-outline-secondary {
  width: 100%;
  border-radius: 27px;
  color: var(--theme-color);
  text-transform: uppercase;
  border-color: var(--theme-color);
}

.authorized-layout {
  display: flex;
  flex-direction: column;
}

.booking-section {
  flex-direction: row;
}

.booking-section .appointment-details-section {
  width: 70%;
}

.booking-section .checkout-section {
  width: 30%;
}

.default-layout .content-section .platform {
  align-self: flex-start;
}

.appointment-page {
  padding-top: calc(var(--header-height) + 48px);
  padding-bottom: var(--header-height);
}
